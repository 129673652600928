/*
 * @Author: yangzonglong
 * @Date: 2021-03-31 11:35:49
 * @version: v1.0.0
 * @Descripttion: 公共reducer
 * @LastEditors: yangzonglong
 * @LastEditTime: 2021-07-30 23:50:48
 * @Auditor: 
 */
import { AnyAction } from 'redux';
import { UserProps } from './../interface';

const userInfo = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo) : null;

interface StateProps {
  loading: boolean;
  userInfo: UserProps | null;
  menuVisible: boolean;
  isSessionExpired: boolean; // 会话过期
  collapsed: boolean
}

export const Types = {
  SETLOADING: 'setLoading',
  SET_USER_INFO: 'setUserInfo',
  SET_MENU_VISIBLE: 'setMenuVisible',
  SET_IS_SESSION_EXPIRED: 'setIsSessionExpired',
  SET_COLLAPSED: 'setCollapsed'
}

const pathname = window.location.pathname;
const pagename = pathname.split('/')[2];

let menuVisible = true;
if(pagename === 'app' && pathname.split('/')[3]) menuVisible = false;

const defaultState = {
  collapsed: false,
  loading: false,
  userInfo,
  isSessionExpired: false,
  refTimeStr: '',
  menuVisible,
}

const commonReducer = (state: StateProps, action: AnyAction) => {
  switch (action.type) {
    
    case Types.SETLOADING:
      return { ...state, loading: action.loading }

    case Types.SET_USER_INFO:
      return { ...state, userInfo: action.userInfo }
    
    case Types.SET_MENU_VISIBLE: 
      return { ...state, menuVisible: action.menuVisible }

    case Types.SET_IS_SESSION_EXPIRED:
      return { ...state, isSessionExpired: action.isSessionExpired }
    
    case Types.SET_COLLAPSED:
      return { ...state, collapsed: action.collapsed }

    default:
      return defaultState
  }
}

export default commonReducer;