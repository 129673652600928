/*
 * @Author: yangzonglong
 * @Date: 2020-05-11 10:14:14
 * @version: v1.0.0
 * @Descripttion: 多语言配置文件
 * @LastEditors: yangzonglong
 * @LastEditTime: 2021-08-24 10:11:14
 * @Auditor: 
 */
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { APP_NAME } from './configs';

const IS_DEV = process.env.NODE_ENV === 'development';
const LOADPATH = '/locales/{{lng}}/{{ns}}.json';

i18n
  // load translation using xhr -> see /public/locales
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    fallbackLng: 'zh-CN',
    ns: ['translation'],
    debug: false,
    backend: {
      // loadPath: IS_DEV ? LOADPATH : `/${APP_NAME}${LOADPATH}`
      loadPath: LOADPATH
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;