/*
 * @Author: yangzonglong
 * @Date: 2021-03-25 09:34:01
 * @version: v1.0.0
 * @Descripttion: 入口文件
 * @LastEditors: yangzonglong
 * @LastEditTime: 2021-08-24 08:47:05
 * @Auditor: 
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';
import history from './history';
import store from './redux/store';
import i18n from './i18n';
import './utils/operationTiming';
import 'normalize.css';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { Types } from '@redux/commonReducer';

const root = document.getElementById('root');
const App = React.lazy(() => import('./App'));
window._core = true; // 标记window，用于子页面查找window

function handleUrl(url) {
  if (!url || url.includes('http') || url.includes('/bk/getEnrollFile/')) {
    return url;
  }
  return '/bk/getEnrollFile/' + url;
}

window.$app = {
  handleUrl,
  t: k => i18n.t(k),
  history: history,
  MOMENT_FORMAT_TIME: 'YYYY/MM/DD HH:mm:ss',
  MOMENT_FORMAT_DATE: 'YYYY/MM/DD',
  hideLoading: () => store.dispatch({ type: Types.SETLOADING, loading: false }),
  showLoading: () => store.dispatch({ type: Types.SETLOADING, loading: true })
};

const ProviderApp = () => (
  <Provider store={store}>
    <ConfigProvider locale={localStorage.i18nextLng === 'en' ? enUS : zhCN}>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </ConfigProvider>
  </Provider>
);

i18n.init().then(() => {
  ReactDOM.render(
    <ProviderApp />,
    root
  );
}).catch(() => {
  ReactDOM.render(
    <ProviderApp />,
    root
  );
});

reportWebVitals();
